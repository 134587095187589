<template>
    <section class="content">
		<div class="card">
            <div class="card-body">
				              <div class="row">
          <div class="col-9">
            <div class="row form-horizontal">
              <div class="col-auto">
                <label class="control-label my-2 ml-2">
                  <h6>Join Date :</h6>
                </label>
              </div>
              <div class="col-auto">
                <div class="input-group mb-3">
                  <input
                    type="text"
                    ref="daterange"
                    class="form-control"
                    style="border-right: 0"
                  />
                  <div class="input-group-append">
                    <div
                      class="input-group-text"
                      style="background-color: #fff"
                    >
                      <span><span class="fa fa-calendar"></span></span>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          <div class="col-3 text-right" v-if="false">
            <div class="btn-group" ref="toolbar">
              <button type="button" data-action="read" class="btn btn-default">
                <i class="fa fa-sync-alt"></i>
              </button>
              <button
                type="button"
                :disabled="downloading"
                v-on:click="downloadXlsx"
                data-action="export-xlsx"
                class="btn btn-default"
              >
                <i class="fa fa-download"></i> Download
              </button>
            </div>
          </div>
        </div>
				
                <div class="btn-area" ref="btnSelected" style="display:none;">
                    <button type="button" @click="confirmRecon" :disabled="processing" class="btn btn-success mb-2">Konfirmasi Pencairan</button>
                </div>
				<table class="table table-hover" ref="tableuser" id="tablshipper">
					<thead>
						<tr>
                            <th>
                                <input type="checkbox" id="checkall" ref="checkall" name="checkall" value="1" @click="selectAll"/>
                            </th>
							<th>ID</th>
							<th>NAMA</th>
							<th>JOIN</th>
							<th>JUMLAH KIRIMAN</th>
							<th>KOMISI DIPROSES</th>
							<th>TOTAL PENDAPATAN</th>
						</tr>
					</thead>
					<tbody @click="handleClick">
					</tbody>
				</table>
			</div>
		</div>

    <!-- Batch Pencairan Afiliasi -->
    <div class="modal" tabindex="-1" role="dialog" ref="formPreview2" data-backdrop="static">
        <div class="modal-dialog" role="document">
            <form role="form" @submit.prevent="submitBatchAfiliasi">
            <div class="modal-content">
                <div class="modal-header">
                    <h5>KONFIRMASI PENCAIRAN KOMISI</h5>
                </div>
                <div class="modal-body">
                    <div class="col-10 text-center mx-auto mb-2">
                        <h5>Periode :</h5>
                        <datepicker v-model="month" placeholder="Pilih periode" @input="setMonth" :options="{format:'mm/yyyy', startView: 'months', minViewMode: 'months', autoclose: true}"/>
                    </div>
                    <div class="col-10 bank card bg-for-light padding mx-auto">
                        <h6>Total affiliator : {{ !totalShipper ? 0 : totalShipper }}</h6>
                        <h6>Total kiriman dari {{ !totalShipper ? 0 : totalShipper }} affiliator : {{ !formItem.total_kiriman ? 0 : formItem.total_kiriman }}</h6>
                        <h6>Total estimasi diterima dari {{ !totalShipper ? 0 : totalShipper }} affiliator : Rp {{ formatPrice(formItem.total_rekon) }}</h6>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" :disabled="processing" class="btn btn-primary">
                        Konfirmasi
                        <span v-if="processing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
                        Tutup
                    </button>
                </div>
            </div>
            </form>
        </div>
    </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth, formatCurrency,openChat } from "@/libs/hxcore";
import $ from "jquery";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from "moment";
import datepicker from '@/components/Datepicker';
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import Swal from "sweetalert2";

export default {
  name: "Shipper Afiliasi",
  data() {
    return {
      errors: [],
      shipmentAmount: [{label: '1 - 5', num: 5}, {label: '6 - 30', num: 30}, {label: '31 - 100', num: 100}, {label: ' > 100', num: 1000}],
      method: "",
      roles: "",
      groups: [],
      memberType: [],
      userCS: [],
      bank: [],
      filter: {},
      month: '',
      dt1: moment().startOf("month"),
      dt2: moment(),
        data_type: "pickupTime",
      disabled: false,
      processing: false,
      formTitle: "Tambah Shipper",
      form: {
        courier_id: [],
        name: "",
        username: "",
        display_name: "",
        email: "",
        membership_type_id: "",
        kontak_hp: "",
        bank_id: 0,
        bank_rekening: "",
        bank_atasnama: "",
        alamat: "",
        active: "",
        usercs_id: "",
        shipment_amount: "",
      },
      selectedCount: 0,
      processing: false,
      totalKiriman: [],
      totalEstimasi: [],
      totalShipper: [],
      groupAffiliate: [],
      formItem: {
        total_kiriman: 0,
        estimasi_reward: 0,
        total_rekon: 0,
      }
    };
  },
  computed: {
    kurirLogo() {
      const fileName = "jne"; //logo.toLowerCase()

      return require(`../assets/img/${fileName}.png`); // the module request
    },
  },
  components: {
    vSelect,
    datepicker
},
watch:{
    $route (to, from){
    //   if(to.path === "/shipper/affiliasi")
    //   {
    //     sessionStorage.filterData='';
	// 	sessionStorage.filterStatus = '';
    //     this.filter = {};
    //     this.table.api().ajax.reload();
    //   }
    }
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    this.userInfo = auth.user();

	if(this.$route.params.filter) {
      this.filter = this.$route.params.filter;
    }

	if(this.filter.dt1 && this.filter.dt2)
    {
      this.dt1 = moment(this.filter.dt1);
      this.dt2 = moment(this.filter.dt2);
    }
    this.dateStart = this.dt1.format('YYYY-MM-DD');
    this.dateEnd = this.dt2.format('YYYY-MM-DD');
    if(this.filter.gid)
    {
      this.groupId = this.filter.gid;
      this.dateStart = '';
      this.dateEnd = '';
	  }
    if(Object.keys(this.filter).length<1)
    {
      if(sessionStorage.filterData)this.filter = JSON.parse(sessionStorage.filterData);
    }
    else{
      sessionStorage.filterData = JSON.stringify(this.filter);
      sessionStorage.filterStatus = 1;
    }
    this.filter.datatype = this.data_type;

  },
  methods: {
    formatPrice(val){
        if(!val || val == 0) return "0";
        return formatCurrency(val);
    },
    handleClick(e) {
        if (e.target.matches(".link-afiliasi")) {
        let route = this.$router.resolve({
            path: "/afiliasi-shipper-detail/" + e.target.dataset.to
        });
        window.open(route.href,"_self");
        } else if (e.target.matches("input[type=checkbox]")) {
                var checkboxes = document.querySelectorAll('.select-order:checked');
                if (checkboxes.length == 0) {
                    this.$refs.checkall.checked = false;
                } else {
                    this.$refs.checkall.checked = true;
                }
                this.selectedCount = checkboxes.length;
                if (this.selectedCount > 0) $(this.$refs.btnSelected).show();
                else $(this.$refs.btnSelected).hide();
                return false;
        }
    },
    setMonth: function (val) {
        this.loadTotalAfiliasi(val);
    },
    loadTotalAfiliasi(month){
        // set data total reward per month (sesuai shipper yg dicentang)
        var allData = { month: this.month, item: this.groupAffiliate };

        // get data total reward
        let url = '/shipper/total_reward_afiliasi';
            authFetch(url, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(allData)
            })
            .then((res) => {
            if (res.status === 201) {
            } else if (res.status === 400) {
            }
            return res.json();
            })
            .then((js) => {
                if (js.data == null) this.formItem = {};
                else this.formItem = js.data;
            });
    },
    confirmRecon: function () {
		const er = this.$refs;
		var checkboxes = document.querySelectorAll('.select-order');
		var selected = [];
        for (var i = 0; i < checkboxes.length; i++) {
			if (checkboxes[i].checked){
                selected.push(checkboxes[i].value);
            }
		}
		this.totalShipper = selected.length; //total yg di-centang
		this.groupAffiliate = selected;
		$(er.formPreview2).modal("show");
	},
    submitBatchAfiliasi: function (ev) {
		const er = this.$refs;
		// const self = this;
        if (!this.formItem.total_kiriman || !this.formItem.total_rekon) {
            Swal.fire("Proses gagal!", `Data tidak ditemukan / sudah pernah dicairkan sebelumnya.`, "error");
        } else {
		var allData = { month: this.month, item: this.groupAffiliate };
        this.processing = true;
        Swal.fire({
            title: "Konfirmasi Pencairan?",
            showCancelButton: true,
            confirmButtonText: `Ya`,
            cancelButtonText: "Tidak",
        }).then((result) => {
            if (result.isConfirmed) {
                authFetch("/shipper/rekonsiliasi_afiliasi", {
					method: 'POST',
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
					body: JSON.stringify(allData)
				})
                .then((res) => {
                return res.json();
                })
                .then((js) => {
				this.processing = false;
                if (js.success) {
                    Swal.fire("Proses Berhasil", `${js.total} affiliator berhasil dicairkan.`, "success");
                    $(er.formPreview2).modal("hide");
                    this.table.api().ajax.reload();
                    this.month = '';
                    this.formItem = {};
                    this.totalShipper = 0;
                    this.$refs.checkall.checked = false;
                    $(this.$refs.btnSelected).hide();
                } else {
                    Swal.fire("Proses gagal", ``, "error");
                    // this.tbl.api().ajax.reload();
                }
                });
            }
        });
        }
        ev.preventDefault();
    },
    selectAll: function (e) {
		var val = e.target.checked;
		var checkboxes = document.querySelectorAll('.select-order');
		for (var i = 0; i < checkboxes.length; i++) {
			checkboxes[i].checked = val;
		}
		if (val && checkboxes.length > 0)
			this.selectedCount = checkboxes.length;
		else this.selectedCount = 0;
		if (this.selectedCount > 0) $(this.$refs.btnSelected).show();
		else $(this.$refs.btnSelected).hide();
	},
    dataType(event) {
      this.data_type = event.target.value;
	  this.filter.datatype = this.data_type;
    //   this.table.api().ajax.reload();
    },
    onDate() {
      // var params = { dt1: this.dateStart, dt2: this.dateEnd };
      // var search = new URLSearchParams(params);
      this.table.api().ajax.reload();
    },
	downloadXlsx: function (e) {
        this.downloading = true;
		var table = $('#tablshipper').DataTable();
		var length = table.page.info().recordsTotal;
		
		if (length > 20000) {
			Swal.fire("Proses gagal", `Maksimal data AWB sebanyak kurang lebih 20.000 data.`, "error");
			this.downloading = false;
			return false;
		}
		
		var filter = JSON.parse(JSON.stringify(this.filter));
			filter.q = $("input[name=q]").val();
			filter.category = $("select[name=category]").val();

		console.log(filter);
		var data = Object.keys(filter)
			.map(
				(key) =>
				encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
			)
			.join("&");
		console.log(data)
		this.loadingContent = true;
		authFetch("/report/shipper/excell", {
				method: "POST",
				body: data,
			})
			.then((response) => {
				if (response.status === 201) {
				} else if (response.status === 400) {
				} else if (response.status === 500){
					Swal.fire("Timeout!", ``, "error");
					this.loading2 = false;
					this.msg = '';
					return false;
				}

				return response.blob();
			})
			.then((blob) => {
				setTimeout(() => {
					this.downloading = false;
				}, 1000);
				this.loadingContent = false;
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement("a");
				a.href = url;
				a.download = "data.xlsx";
				document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
				a.click();
				a.remove(); //afterwards we remove the element again
			});
    },
    submitForm: function (ev) {
      const e = this.$refs;
      var allData = { group: this.form };
      var urlSubmit = "/shipper/affiliasi";
      if (this.method == "PUT") urlSubmit = "/shipper/affiliasi" + this.form.id;

      authFetch(urlSubmit, {
        method: this.method,
        body: JSON.stringify(allData),
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.success) {
            console.log(js.details);

            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          }
          this.table.api().ajax.reload();
          $(e.formDialog).modal("hide");
        });

      ev.preventDefault();
    },
  },

  mounted() {
    const e = this.$refs;
    var self = this;
    new Daterangepicker(
      this.$refs.daterange,
      {
        startDate: !this.dt1 ? moment().day(-31) : this.dt1,
        endDate: !this.dt2 ? moment() : this.dt2,
        locale: {
					format: 'DD/MM/YYYY'
				},
      },
      function (d1, d2) {
        self.dateStart = d1.format("YYYY-MM-DD");
        self.dateEnd = d2.format("YYYY-MM-DD");
        self.data_type = self.data_type;
        self.filter.datatype = self.data_type;
        self.filter.dt1 = self.dateStart;
        self.filter.dt2 = self.dateEnd;
        self.onDate();
      }
    );
    //console.log('roles', this.$route.params.roles);
    this.table = createTable(e.tableuser, {
      title: "",
      roles: ['read'],
      ajax: "/shipper/affiliasi",
      frame: true,
      scrollX: true,
      dom: "<'row'<'col-sm-12 col-md-6 btn-area-selected'><'col-sm-12 col-md-6'f>>" +
				"<'row'<'col-sm-12'tr>>" +
				"<'row'<'col-sm-12 col-md-2'l><'col-sm-12 col-md-3'i><'col-sm-12 col-md-7'p>>",
      processing: true,
      autoWidth:false,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
        paramData: function (d) {
            var filter = JSON.parse(JSON.stringify(self.filter));
            //d = filter;
            //d.dev = filter.delivered;
            d.dt1 = self.dateStart;
            d.dt2 = self.dateEnd;
            d.datatype = self.data_type;
            var filter = JSON.parse(JSON.stringify(self.filter));
            for (const key in filter) {
                d[key] = filter[key] === false ? 0 : filter[key];
            }
        },
      columns: [
        {
			sortable: false,
			"data": "checkall", // can be null or undefined
			"defaultContent": '<input type="checkbox">',
			render: function (data, type, row, meta) {
				if(!row.estimasi){
                    return ('');
                } else {
                    return (
					'<input type="checkbox" class="select-order" data-id="' + row.id +'" value="' + row.id + '" />'
                    );
                }
			},
		},
        { data: "id" },
        { data: "nama",
        render: function (data, type, row, meta) {
          return (
              '<a class="link link-afiliasi text-info" style="cursor:pointer;" data-to="' + row.id + '">' + data + "</a>"
          );
        }
        },
        { data: "joined_on",
        render: function (data, type, row, meta) {
          return moment(data).format("DD/MM/YYYY HH:mm");
        }, },
        { data: "total_reward" },
        { data: "estimasi",
        render: function (data, type, row, meta) {
          var estimasi = data?data:0;
          return `Rp. ${estimasi}`;
        }, },
        { data: "komisi",
        render: function (data, type, row, meta) {
          var diterima = data?data:0;
          return `Rp. ${diterima}`;
        }, },
      ],
      filterBy: [1],
      initComplete: function () {
        $(e.btnSelected).appendTo('.btn-area-selected');
        $('.loading-overlay').removeClass('show');
      },
      rowCallback: function (row, data) {
      },
      buttonClick: (evt) => {
        if (evt.role == "create") {
          self.form = {};
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah Shipper";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          //get kurir
          let data = evt.data.kurir; //nama kurir
          let id_kurir = evt.data.courier_id; //id kurir
          let len = data.length;
          let len2 = id_kurir.length;

          if (evt.data.kurir == []) {
            self.form.courier_id = "";
          } else {
            self.form.courier_id = [];
            if (len < 1 || len2 < 1) {
              self.form.courier_id;
            } else {
              for(let i = 0; i < len && i < len2; i++)
              {
                self.form.courier_id.push({
                      label: data[i],
                      courier_id: id_kurir[i],
                });
              }
            }
          }

          self.form.id = evt.data.id;
          self.form.name = evt.data.name;
          self.form.display_name = evt.data.display_name;
          self.form.username = evt.data.username;
          self.form.email = evt.data.email;
          self.form.membership_type_id = evt.data.membership_type_id;
          self.form.kontak_hp = evt.data.kontak_hp;
          self.form.bank_id = evt.data.bank_id;
          self.form.bank_rekening = evt.data.bank_rekening;
          self.form.bank_atasnama = evt.data.bank_atasnama;
          self.form.alamat = evt.data.alamat;
          self.form.usercs_id = evt.data.usercs_id;
          self.form.shipment_amount = evt.data.shipment_amount;
          self.form.marking = evt.data.marking;

          if (evt.data.active == 0 || !evt.data.active) {
            self.form.active = false;
          } else {
            self.form.active = true;
          }
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit Shipper";
          $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          self.form = evt.data;
          authFetch("/shipper/affiliasi" + evt.data.id, {
            method: "DELETE",
            body: "id=" + evt.data.id,
          })
            .then((res) => {
              return res.json();
            })
            .then((js) => {
              this.table.api().ajax.reload();
            });
        }
      },
    });
  },
};
</script>
<style type="text/css">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>